import UIFullWidthLayout from '@paperstac/design/lib/templates/FullWidthLayout';
import { SxProp } from '@paperstac/design/lib/utilities/sx';
import Head from 'next/head';
import React from 'react';
import Footer from './Footer';
import Header from './Header';

type Props = SxProp & {
  children: React.ReactNode;
  pageTitle: string;
};

const FullWidthLayout = React.memo(({ children, pageTitle, sx }: Props) => {
  return (
    <>
      <Head>
        <title>{pageTitle}</title>
      </Head>
      <UIFullWidthLayout footer={<Footer />} header={<Header />} sx={sx}>
        {children}
      </UIFullWidthLayout>
    </>
  );
});

FullWidthLayout.displayName = 'FullWidthLayout';

export default FullWidthLayout;
