import NoteClosingsHome from '@paperstac/design/lib/pages/NoteClosingsHome';
import { REGISTER } from '@paperstac/routes/lib/authRoutes';
import type { NextPage } from 'next';
import React from 'react';
import FullWidthLayout from '../components/FullWidthLayout';

const Home: NextPage = () => (
  <FullWidthLayout pageTitle="Painless Mortgage Note Closings | NoteClosings" sx={{ p: 0 }}>
    <NoteClosingsHome registerHref={REGISTER} />
  </FullWidthLayout>
);

export default Home;
